@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

body {
  margin: 0;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.group:hover button {
  background-color: #006bff; /* Background color for the button on hover of div */
}

select {
  border-right: 16px solid transparent;
}

.popup {
  opacity: 0;
  transition: opacity 0.3s ease; /* Duration and easing of the popup */
}

.popup.visible {
  opacity: 1;
}
