.App {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 8rem;
}

.container {
  padding-left: 9rem;
  padding-right: 9rem;
  max-width: 100%;
}

@media screen and (max-width: 1023px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
